import React from "react";
import { graphql } from "gatsby";
import Layout from "../components/layout";
import {DataProvider} from "../contentful/data-provider";
import {Helmet} from "react-helmet";
import ogimage from "../images/ogimages/home.png";
import dydxLogo from "../images/logos/dydx-logo.svg";
import osmosisLogo from "../images/logos/osmosis-logo.svg";
import skipLogo from "../images/logos/skip-logo.svg";
import axelarLogo from "../images/logos/axelar-logo.svg";
import uxLogo from "../images/logos/ux-logo.png";
import unionLogo from "../images/logos/union-logo.png";
import strideLogo from "../images/logos/stride-logo.svg";
import cosmosLogo from "../images/logos/cosmos-logo.svg";
import illustration4 from "../images/illustrations/illustration-4.svg";

export default function Home({ data }) {
    const dataProvider = new DataProvider(data);
    return (
        <Layout>
            <Helmet
                meta={[
                    {
                        property: `og:title`,
                        content: 'Agoric - Deploy Secure DeFi in JavaScript',
                    },
                    {
                        property: `twitter:title`,
                        content: 'Agoric - Deploy Secure DeFi in JavaScript',
                    },
                    {
                        property: `og:type`,
                        content: `website`,
                    },
                    {
                        property: `twitter:image`,
                        content: ogimage,
                    },
                    {
                        property: `og:image`,
                        content: ogimage,
                    },
                    {
                        property: `twitter:card`,
                        content: `summary_large_image`,
                    },
                ]}
            >
                <title>Agoric - Deploy Secure DeFi in JavaScript</title>
            </Helmet>
            <main className={"main-page new-main-page"}>
                {dataProvider.getCurrentPageSections().map((section, index) => {
                    if (index === 3) {
                        return (
                            <>
                                <div className={'orchestration-page'}>
                                    <section className={'logo-section'}>
                                        <div className={'container'}>
                                            <p className={'text-center mb-5'}>Orchestrate seamless user experiences with
                                                a
                                                growing number of connected chains and services.</p>
                                            <div
                                                className={'d-flex flex-row flex-wrap justify-content-center align-items-center'}>
                                                <div className={'col-auto p-3'}>
                                                    <img src={dydxLogo} alt="DYDX"/>
                                                </div>
                                                <div className={'col-auto p-3'}>
                                                    <img src={osmosisLogo} alt="Osmosis"/>
                                                </div>
                                                <div className={'col-auto p-3'}>
                                                    <img src={skipLogo} alt="Skip"/>
                                                </div>
                                                <div className={'col-auto p-3'}>
                                                    <img src={axelarLogo} alt="Axelar"/>
                                                </div>
                                                <div className={'col-auto p-3'}>
                                                    <img src={uxLogo} alt="UX"/>
                                                </div>
                                                <div className={'col-auto p-3'}>
                                                    <img src={unionLogo} alt="Union"/>
                                                </div>
                                                <div className={'col-auto p-3'}>
                                                    <img src={strideLogo} alt="Stride"/>
                                                </div>
                                                <div className={'col-auto p-3'}>
                                                    <img src={cosmosLogo} alt="Cosmos"/>
                                                </div>
                                            </div>
                                        </div>
                                    </section>
                                    <section className="text-with-image-section right-to-left bg-blue">
                                        <div className="container">
                                            <div className="row align-items-center">
                                                <div className="col-left col-lg-6 order-lg-2 order-2">
                                                    <h2>The world is going <br/>multi-chain</h2>
                                                    <p>
                                                        Users are increasingly exploring multi-chain solutions to solve
                                                        their problems. Orchestration gives developers the platform to
                                                        program those solutions, and access to the Total Reachable Value
                                                        of
                                                        the entire interchain ecosystem.
                                                    </p>

                                                    <div className={'d-flex stat'}>
                                                        <div className={'col'}>
                                                            <div className={'value'}>
                                                                59B+
                                                            </div>
                                                            IBC Market Cap
                                                        </div>
                                                        <div className={'col'}>
                                                            <div className={'value'}>
                                                                230K
                                                            </div>
                                                            IBC DAU
                                                        </div>
                                                    </div>
                                                </div>
                                                <div
                                                    className="col-right col-lg-6 order-lg-1 order-1 text-lg-left pe-5">
                                                    <div className="image mx-auto d-table">
                                                        <img src={illustration4} alt="The world is going multi-chain"/>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </section>
                                </div>
                                {dataProvider.renderPageSection(section)}
                            </>
                        )
                    }
                    if (index === 1) {
                        return (
                            <div id={'latest-apps'}>{dataProvider.renderPageSection(section)}</div>
                        )
                    }
                    return <>{dataProvider.renderPageSection(section)}</>;
                })}
            </main>
        </Layout>
    );
}

export const query = graphql`
    query {
        contentfulPage(contentful_id: { eq: "3v9VVfsXNK5i6nVzu4bdlz" }) {
            ...CurrentPageDetails
        }
        allContentfulEntry {
            edges {
                node {
                    ...AllEntryDetails
                }
            }
        }
        allContentfulBlogPost(sort: {fields: publishedAt, order: DESC}, limit: 3) {
            nodes {
                id
                title
                slug
                image {
                    title
                    url
                }
                author
                publishedAt(formatString: "MMMM D, Y")
                tags
                category {
                    title
                    slug
                }
                body {
                    raw
                }
                excerpt {
                    raw
                }
            }
        }
        allContentfulEventPost(sort: {fields: date, order: ASC}) {
            nodes {
                image {
                    url
                    title
                }
                title
                jointEventUrl
                slug
                excerpt
                body {
                    raw
                }
                id
                date(formatString: "MMMM DD, YYYY")
            }
        }
    }
`;
